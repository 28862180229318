'use client'

import { cn } from '~/core/ui/utils'
import { cva } from 'class-variance-authority'

const dotVariants = cva('rounded-full inline-block', {
  variants: {
    size: {
      xl: 'w-2 h-2',
      lg: 'w-1.5 h-1.5',
      sm: 'w-1.5 h-1.5',
      md: 'w-1.5 h-1.5'
    },
    color: {
      green: 'bg-green-500',
      purple: 'bg-purple-500',
      yellow: 'bg-yellow-500',
      orange: 'bg-orange-500',
      red: 'bg-red-500',
      blue: 'bg-blue-500',
      'dark-blue': 'bg-blue-1100',
      gray: 'bg-gray-400',
      white: 'bg-gray-400',
      'chart-sourced': 'bg-chart-50',
      'chart-applied': 'bg-chart-100',
      'chart-screening': 'bg-chart-500',
      'chart-interview': 'bg-chart-400',
      'chart-offer': 'bg-chart-200',
      'chart-hired': 'bg-chart-300'
    }
  },
  defaultVariants: {
    size: 'lg',
    color: 'gray'
  }
})

type IDotColorProps =
  | 'green'
  | 'purple'
  | 'yellow'
  | 'orange'
  | 'red'
  | 'blue'
  | 'gray'
  | 'white'
  | 'chart-sourced'
  | 'chart-applied'
  | 'chart-screening'
  | 'chart-interview'
  | 'chart-offer'
  | 'chart-hired'

interface DotProps {
  color?: IDotColorProps
  size?: 'sm' | 'lg' | 'xl' | 'md'
  className?: string
}

const Dot = ({ size = 'lg', color = 'gray', className = '' }: DotProps) => {
  return <span className={cn(dotVariants({ color, size, className }))} />
}

export { Dot }
export type { DotProps, IDotColorProps }
