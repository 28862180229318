'use client'

import * as AvatarWrapper from '@radix-ui/react-avatar'
import { cva } from 'class-variance-authority'
import {
  MouseEventHandler,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  CameraIcon,
  DefaultAvatarImg,
  DefaultLogoImg,
  LogoATS,
  VerifyIcon,
  CancelAttendIcon,
  AttendIcon
} from '~/core/ui/FillIcons'
import { cn } from '~/core/ui/utils'

export const defaultColorBgAvatar = '#E4EEFD'

const randomColor = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const listAvatarInitials: { [id: string]: string } = {
  '#F8EDED':
    'bg-ava-bg-50 text-ava-text-50 dark:text-ava-bg-50 dark:bg-ava-text-50',
  '#F8EBE2':
    'bg-ava-bg-100 text-ava-text-100 dark:text-ava-bg-100 dark:bg-ava-text-100',
  '#F8F3E2':
    'bg-ava-bg-200 text-ava-text-200 dark:text-ava-bg-200 dark:bg-ava-text-200',
  '#EAF0E7':
    'bg-ava-bg-300 text-ava-text-300 dark:text-ava-bg-300 dark:bg-ava-text-300',
  '#E1EFE9':
    'bg-ava-bg-400 text-ava-text-400 dark:text-ava-bg-400 dark:bg-ava-text-400',
  '#E3EFF0':
    'bg-ava-bg-500 text-ava-text-500 dark:text-ava-bg-500 dark:bg-ava-text-500',
  '#E4EEFD':
    'bg-ava-bg-600 text-ava-text-600 dark:text-ava-bg-600 dark:bg-ava-text-600',
  '#DFE3FF':
    'bg-ava-bg-700 text-ava-text-700 dark:text-ava-bg-700 dark:bg-ava-text-700',
  '#E7E4F8':
    'bg-ava-bg-800 text-ava-text-800 dark:text-ava-bg-800 dark:bg-ava-text-800',
  '#F1E8FA':
    'bg-ava-bg-900 text-ava-text-900 dark:text-ava-bg-900 dark:bg-ava-text-900',
  '#FFFFFF': 'bg-white text-gray-900 border border-[1.5px] border-gray-300'
}

const avatarRootVariants = cva(
  `flex relative hover:after:absolute hover:after:content-[''] hover:after:bg-black hover:after:w-full hover:after:h-full hover:after:opacity-25`,
  {
    variants: {
      size: {
        '2xs': 'w-4 h-4',
        xs: 'w-5 h-5',
        sm: 'w-6 h-6',
        md: 'w-8 h-8',
        lg: 'w-10 h-10',
        xl: 'w-12 h-12',
        '2xl': 'w-14 h-14',
        '3xl': 'w-16 h-16',
        '4xl': 'w-[72px] h-[72px]',
        '8xl': 'w-[120px] h-[120px]'
      },
      shape: {
        circular: 'rounded-full hover:after:rounded-full',
        rounded: 'rounded hover:after:rounded'
      },
      group: {
        default: '',
        group: 'ring-2 ring-white dark:ring-gray-900'
      }
    },
    defaultVariants: {
      size: '3xl',
      shape: 'circular',
      group: 'default'
    }
  }
)

const avatarVariants = cva(
  `inline-block h-full w-full focus:outline-none focus:ring-1 focus:ring-primary-300 focus-visible:ring-1 dark:focus:ring-primary-700`,
  {
    variants: {
      group: {
        default: '',
        group: 'ring-2 ring-white dark:ring-gray-900'
      },
      shape: {
        circular: 'rounded-full',
        rounded: 'rounded'
      }
    },
    defaultVariants: {
      group: 'default',
      shape: 'circular'
    }
  }
)

const avatarStatusVariants = cva(
  'absolute bottom-0 right-0 block -translate-y-0 translate-x-0 transform ring-white dark:ring-gray-900',
  {
    variants: {
      size: {
        '2xs': 'w-1 h-1 ring-1',
        xs: 'w-1.5 h-1.5 ring-2',
        sm: 'w-2 h-2 ring-2',
        md: 'w-2.5 h-2.5 ring-2',
        lg: 'w-3 h-3 ring-2',
        xl: 'w-3.5 h-3.5 ring-2',
        '2xl': 'w-4 h-4 ring-2',
        '3xl': 'w-[18px] h-[18px] ring-2',
        '4xl': 'w-[18px] h-[18px] ring-2',
        '8xl': 'w-[18px] h-[18px] ring-2'
      },
      shape: {
        circular: 'rounded-full',
        rounded: 'rounded'
      },
      status: {
        online: 'bg-green-500',
        offline: 'bg-gray-300'
      }
    },
    defaultVariants: {
      size: '3xl',
      shape: 'circular',
      status: 'online'
    }
  }
)

const avatarVerifyVariants = cva(
  'absolute bottom-0 right-0 block -translate-y-0 translate-x-0 transform',
  {
    variants: {
      size: {
        '2xs': 'w-1 h-1',
        xs: 'w-1.5 h-1.5',
        sm: 'w-2 h-2',
        md: 'w-2.5 h-2.5',
        lg: 'w-3 h-3',
        xl: 'w-3.5 h-3.5',
        '2xl': 'w-4 h-4',
        '3xl': 'w-[18px] h-[18px]',
        '4xl': 'w-[18px] h-[18px]',
        '8xl': 'w-[18px] h-[18px]'
      },
      shape: {
        circular: 'rounded-full',
        rounded: 'rounded'
      }
    },
    defaultVariants: {
      size: '3xl',
      shape: 'circular'
    }
  }
)

const avatarOverlayVariants = cva('absolute left-0 top-0', {
  variants: {
    size: {
      '2xs': 'w-4 h-4',
      xs: 'w-5 h-5',
      sm: 'w-6 h-6',
      md: 'w-8 h-8',
      lg: 'w-10 h-10',
      xl: 'w-12 h-12',
      '2xl': 'w-14 h-14',
      '3xl': 'w-16 h-16',
      '4xl': 'w-[72px] h-[72px]',
      '8xl': 'w-[120px] h-[120px]'
    },
    disabled: {
      default: '',
      disabled: 'opacity-30 bg-white hover:bg-gray-900'
    },
    alt: {
      default: '',
      alt: 'dark:hover:bg-gray-900'
    },
    shape: {
      circular: 'rounded-full',
      rounded: 'rounded'
    }
  },
  defaultVariants: {
    size: '3xl',
    disabled: 'default',
    alt: 'default',
    shape: 'circular'
  }
})

const avatarUploadOverlayVariants = cva(
  'absolute cursor-pointer left-0 top-0 flex justify-center items-end opacity-0 hover:opacity-100 hover:bg-black/25',
  {
    variants: {
      size: {
        '2xs': 'w-4 h-4',
        xs: 'w-5 h-5',
        sm: 'w-6 h-6',
        md: 'w-8 h-8',
        lg: 'w-10 h-10',
        xl: 'w-12 h-12',
        '2xl': 'w-14 h-14',
        '3xl': 'w-16 h-16',
        '4xl': 'w-[72px] h-[72px]',
        '8xl': 'w-[120px] h-[120px]'
      },
      disabled: {
        default: '',
        disabled: 'opacity-30 bg-black hover:bg-gray-900'
      },
      shape: {
        circular: 'rounded-full',
        rounded: 'rounded'
      }
    },
    defaultVariants: {
      size: '3xl',
      disabled: 'default',
      shape: 'circular'
    }
  }
)

const avatarUploadOverlayIconVariants = cva(
  'flex items-center justify-center',
  {
    variants: {
      size: {
        '2xs': 'mb-0.5 w-1.5 h-[5px]',
        xs: 'mb-0.5 w-1.5 h-[5px]',
        sm: 'mb-[3px] w-2 h-1.5',
        md: 'mb-1 w-2 h-1.5',
        lg: 'mb-1 w-2.5 h-2',
        xl: 'mb-[5px] w-2.5 h-2',
        '2xl': 'mb-1.5 w-2.5 h-2',
        '3xl': 'mb-[7px] w-2.5 h-2',
        '4xl': 'mb-[7px] w-2.5 h-2',
        '8xl': 'mb-[7px] w-2.5 h-2'
      }
    },
    defaultVariants: {
      size: '3xl'
    }
  }
)

const avatarFallbackRootVariants = cva(
  'flex h-full w-full items-center justify-center',
  {
    variants: {
      group: {
        default: '',
        group: 'ring-2 ring-white dark:ring-gray-900'
      },
      shape: {
        circular: 'rounded-full',
        rounded: 'rounded'
      }
    },
    defaultVariants: {
      group: 'default',
      shape: 'circular'
    }
  }
)

const avatarPlaceholderVariants = cva(
  'inline-block h-full w-full overflow-hidden bg-gray-200 text-white dark:bg-gray-600 dark:text-gray-400',
  {
    variants: {
      shape: {
        circular: 'rounded-full',
        rounded: 'rounded'
      }
    },
    defaultVariants: {
      shape: 'circular'
    }
  }
)

const avatarFallbackFocusVariants = cva(
  'flex h-full w-full items-center justify-center focus:outline-none focus:ring-1 focus:ring-primary-300 focus-visible:ring-1 dark:focus:ring-primary-700',
  {
    variants: {
      shape: {
        circular: 'rounded-full',
        rounded: 'rounded'
      }
    },
    defaultVariants: {
      shape: 'circular'
    }
  }
)

const avatarFallbackVariants = cva(
  'font-medium text-gray-500 dark:text-gray-300',
  {
    variants: {
      size: {
        '2xs': 'text-6xs',
        xs: 'text-6xs',
        sm: 'text-5xs',
        md: 'text-2xs',
        lg: 'text-sm',
        xl: 'text-base',
        '2xl': 'text-lg',
        '3xl': 'text-xl',
        '4xl': 'text-xl',
        '8xl': 'text-xl'
      }
    },
    defaultVariants: {
      size: '3xl'
    }
  }
)

const avatarCountRootVariants = cva(
  'relative flex items-center justify-center',
  {
    variants: {
      size: {
        '2xs': 'w-4 h-4',
        xs: 'w-5 h-5',
        sm: 'w-6 h-6',
        md: 'w-8 h-8',
        lg: 'w-10 h-10',
        xl: 'w-12 h-12',
        '2xl': 'w-14 h-14',
        '3xl': 'w-16 h-16',
        '4xl': 'w-[72px] h-[72px]',
        '8xl': 'w-[120px] h-[120px]'
      }
    },
    defaultVariants: {
      size: '3xl'
    }
  }
)

const avatarCountVariants = cva('h-full w-full overflow-hidden rounded-full', {
  variants: {
    group: {
      default: '',
      group: 'ring-2 ring-white dark:ring-gray-900'
    }
  },
  defaultVariants: {
    group: 'default'
  }
})

const avatarCountFallbackVariants = cva(
  'font-medium text-gray-600 dark:text-gray-300',
  {
    variants: {
      size: {
        '2xs': 'text-6xs',
        xs: 'text-6xs',
        sm: 'text-5xs',
        md: 'text-2xs',
        lg: 'text-sm',
        xl: 'text-base',
        '2xl': 'text-lg',
        '3xl': 'text-xl',
        '4xl': 'text-xl',
        '8xl': 'text-xl'
      }
    },
    defaultVariants: {
      size: '3xl'
    }
  }
)

type ImageLoadingStatus = 'idle' | 'loading' | 'loaded' | 'error'
function useImageLoadingStatus(src?: string) {
  const [loadingStatus, setLoadingStatus] = useState<ImageLoadingStatus>('idle')

  useEffect(() => {
    if (['loaded'].includes(loadingStatus)) return

    if (!src) {
      setLoadingStatus('error')
      return
    }

    let isMounted = true
    const image = new window.Image()

    const updateStatus = (status: ImageLoadingStatus) => () => {
      if (!isMounted) return
      setLoadingStatus(status)
    }

    setLoadingStatus('loading')
    image.onload = updateStatus('loaded')
    image.onerror = updateStatus('error')
    image.src = src

    return () => {
      isMounted = false
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  return loadingStatus
}

const getShortName = (alt: string) => {
  if (alt) {
    const words = alt?.trim().split(' ')
    if (alt.length == 0) {
      return 'N/A'
    } else if (words?.length == 1) {
      return words[0] ? words[0][0]?.toUpperCase() : ''
    } else {
      if (words[0] && words[0][0]) {
        return (
          words[0][0] + ((words[words?.length - 1] || [])[0] || '')
        ).toUpperCase()
      }
    }
  }
  return 'N/A'
}

type AvatarSize =
  | '2xs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '8xl'

interface AvatarProps {
  size?: AvatarSize
  src?: string
  alt?: string
  count?: number
  statusIcon?:
    | 'online'
    | 'verified'
    | 'offline'
    | 'default'
    | 'cancelAttendInterview'
    | 'attendInterview'
  className?: string
  classNameWrapper?: string
  classNameAvatarFallback?: string
  isDisabled?: boolean
  color?: string
  isGroup?: boolean
  tabIndex?: number
  shape?: 'rounded' | 'circular'
  onClick?: MouseEventHandler<HTMLDivElement>
  defaultAvatar?: boolean
  typeUpload?: boolean

  colorFillLogo?: 'primary' | 'white'
  isLogo?: boolean
}

const Avatar = forwardRef<
  React.ElementRef<typeof AvatarWrapper.Root>,
  AvatarProps
>(
  (
    {
      size = '3xl',
      isDisabled = false,
      color = '',
      statusIcon = 'default',
      shape = 'circular',
      className = '',
      classNameWrapper = '',
      classNameAvatarFallback = '',
      onClick,
      defaultAvatar = true,
      colorFillLogo = 'primary',
      isLogo = false,
      typeUpload = false,
      ...props
    },
    ref
  ) => {
    const imageLoadingStatus = useImageLoadingStatus(props.src)
    const imageFocusRef = useRef<HTMLImageElement>(null)
    const imageDefaultFocusRef = useRef<HTMLImageElement>(null)
    const gerRandomColor = useMemo(() => {
      let flatColors = Object.keys(listAvatarInitials).map(
        (key) => listAvatarInitials[key]
      )
      let randomIndex = randomColor(0, flatColors.length - 1)
      return listAvatarInitials[color?.toUpperCase()] || flatColors[randomIndex]
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [color, props.alt])

    if (props.count) {
      return (
        <div className={cn(avatarCountRootVariants({ size }))}>
          <div
            className={cn(
              avatarCountVariants({
                group: props.isGroup ? 'group' : 'default',
                className
              })
            )}>
            <span className="flex h-full w-full items-center justify-center bg-gray-200 font-medium dark:bg-gray-600">
              <span className={cn(avatarCountFallbackVariants({ size }))}>
                +{props.count > 99 ? '99' : props.count}
              </span>
            </span>
          </div>
        </div>
      )
    }

    const renderStatus = () => {
      if (statusIcon === 'offline') {
        return (
          <span
            className={cn(
              avatarStatusVariants({ size, shape, status: 'offline' })
            )}
          />
        )
      }

      if (statusIcon === 'online') {
        return (
          <span
            className={cn(
              avatarStatusVariants({ size, shape, status: 'online' })
            )}
          />
        )
      }

      if (statusIcon === 'verified') {
        return (
          <span className={cn(avatarVerifyVariants({ size, shape }))}>
            <VerifyIcon />
          </span>
        )
      }

      if (statusIcon === 'attendInterview') {
        return (
          <span className={cn(avatarVerifyVariants({ size, shape }))}>
            <AttendIcon />
          </span>
        )
      }

      if (statusIcon === 'cancelAttendInterview') {
        return (
          <span className={cn(avatarVerifyVariants({ size, shape }))}>
            <CancelAttendIcon />
          </span>
        )
      }

      return null
    }

    const renderAvatarPlaceholderIcon = () => {
      return (
        <span
          ref={imageDefaultFocusRef}
          className={cn(avatarPlaceholderVariants({ shape }))}>
          {defaultAvatar ? <DefaultAvatarImg /> : <DefaultLogoImg />}
        </span>
      )
    }

    if (isLogo) return <LogoATS colorFillLogo={colorFillLogo} />

    return (
      <div
        className={cn(
          'relative flex items-center justify-center',
          classNameWrapper
        )}
        onClick={onClick}>
        <span
          ref={ref}
          className={cn(
            avatarRootVariants({
              size,
              shape,
              group: props.isGroup ? 'group' : 'default',
              className
            })
          )}>
          {props.src && imageLoadingStatus !== 'error' ? (
            <img
              tabIndex={props.tabIndex}
              ref={imageFocusRef}
              className={cn(
                avatarVariants({
                  shape,
                  group: props.isGroup ? 'group' : 'default'
                })
              )}
              src={props.src}
              alt={props.alt}
            />
          ) : (
            <div
              className={cn(
                avatarFallbackRootVariants({
                  shape,
                  group: props.isGroup ? 'group' : 'default',
                  className
                })
              )}>
              {props?.alt && defaultAvatar ? (
                <span
                  tabIndex={props.tabIndex}
                  ref={imageDefaultFocusRef}
                  className={cn(
                    avatarFallbackVariants({
                      size,
                      className: classNameAvatarFallback
                    }),
                    avatarFallbackFocusVariants({
                      shape,
                      className: gerRandomColor
                    })
                  )}>
                  {getShortName(props.alt)}
                </span>
              ) : (
                renderAvatarPlaceholderIcon()
              )}
            </div>
          )}

          {typeUpload ? (
            <div
              onClick={() => {
                if (!isDisabled) {
                  imageFocusRef?.current?.focus()
                  imageDefaultFocusRef?.current?.focus()
                }
              }}
              className={cn(
                avatarUploadOverlayVariants({
                  size,
                  disabled: isDisabled ? 'disabled' : 'default',
                  shape
                })
              )}>
              <div
                className={cn(
                  avatarUploadOverlayIconVariants({
                    size
                  })
                )}>
                <CameraIcon />
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                if (!isDisabled) {
                  imageFocusRef?.current?.focus()
                  imageDefaultFocusRef?.current?.focus()
                }
              }}
              className={cn(
                avatarOverlayVariants({
                  size,
                  disabled: isDisabled ? 'disabled' : 'default',
                  alt: props.alt ? 'alt' : 'default',
                  shape
                })
              )}
            />
          )}
        </span>

        {renderStatus()}
      </div>
    )
  }
)

Avatar.displayName = 'Avatar'

export { Avatar, listAvatarInitials }
export type { AvatarProps, AvatarSize }
